<template>
	<div class="partial partial--users users">
		<div class="partial__heading">
			<h1 class="partial__title">Users</h1>
			<div class="partial__actions">
				<router-link :to="{name: 'user' }" class="mdc-button mdc-button--primary mdc-button--outline">
					<div class="mdc-button__ripple"></div>
					<i class="material-icons mdc-button__icon" aria-hidden="true">add</i>
					<span class="mdc-button__label">Add User</span>
				</router-link>
			</div>
		</div>
		<div class="partial__body">
			<card>
				<list-users :users="users" />
				<pagination :count="users.length" :limit="limit" />
			</card>
		</div>
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListUsers from '@/components/ListUsers'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

export default {
	name: 'PartialUsers',
	components: {
		Card,
		ListUsers,
		Modal,
		Pagination,
	},
	data: () => ({
		orderBy: 'dateCreated',
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'user'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		users() {
			return this.$store.getters['user/users'].map(u => {
				u.nameEmail = [u.profileName, u.email].filter(n => n).join('<br />')
				return u
			})
		},
		limit() {
			return this.$route.query.limit || 20
		},
	},
	methods: {
		hideModal(clicked) {
			if (clicked) {
				this.$router.back()
			}
		},
	},
	metaInfo() {
		return {
			title: this.modalTitle || `Users`,
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(to, from) {
				if ((to && to.name == 'users') && (from && from.name == 'user')) return
				const { orderBy, startAt } = to.query
				this.$store.dispatch('user/list', { orderBy, limit: this.limit, startAt, })
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
