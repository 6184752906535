<template>
	<div class="modal modal--user modal-user">
		<div class="modal-user__form">
			<form-user :user="user" />
		</div>
		<div class="modal-user__stats">
			<dl>
				<dt>Last Login</dt>
				<dd :title="user.dateLogin | timeAgo">
					{{ user.dateLogin | date('PPp')}}<br />
					<a @click="resetLoginFail" v-if="user.uid">Reset Login Counter</a>
				</dd>
				<dt>Date Created</dt>
				<dd>{{ user.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ user.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ user.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ user.modifiedByName }}</dd>
			</dl>
		</div>
	</div>
</template>

<script>
import FormUser from '@/components/FormUser'

export default {
	name: 'ModalUser',
	components: {
		FormUser,
	},
	mounted() {
		if (this.$route.params.uid) {
			this.$store.dispatch('user/get', this.$route.params.uid)
		}
	},
	destroyed() {
		this.$store.dispatch('user/unset')
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		user() {
			return this.$store.getters['user/user']
		},
	},
	methods: {
		resetLoginFail() {

		},
	},
}
</script>

<style scoped lang="scss">
.modal-user {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
