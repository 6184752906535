<template>
	<div class="list list--users list-users">
		<table-list
			:items="users"
			:loading="isLoading"
		>
			<template slot="header">
				<span
					:class="`users-header-item users-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					<component :is="orderBy[key] ? 'a' : 'span'" @click="sort(key)"> {{ col }}</component>
					<ion-icon :name="query.order == 'asc' ? `caret-up` : `caret-down`" v-if="orderBy[key] && orderBy[key] == query.orderBy"></ion-icon>
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`users-item users-item--${key} users-item--${item['status']}`"
					:key="`item_${key}`"
					:to="{ name: 'user', params: { uid: item.uid } }"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
				>
				</router-link>
			</template>

		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'

import TableList from '@/components/TableList'
import { UserStatus, UserLevel, } from '@/lib/enums'

export default {
	name: 'ListUsers',
	components: {
		TableList,
	},
	props: {
		users: {
			type: Array,
			default: () =>([]),
		},
		clients: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			nameEmail: 'Name',
			// email: 'Email',
			'client.name': 'Client',
			permissions: 'Level',
			dateLogin: 'Last Login',
			status: 'Status',
		},
		order: 'asc',
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		query() {
			return this.$route.query
		},
		orderBy() {
			let orderBy = {
				// nameEmail: 'firstName',
				// dateCreated: 'dateCreated',
				// organization: 'organization.name',
				// 'settings.salesforceId': 'salesforceId',
				dateLogin: 'dateLogin',
				status: 'status',
			}

			return orderBy
		},
	},
	methods: {
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
					try {
						value = `${format(parseISO(value), 'PPp')}`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'dateLogin':
					if (!value) return '(no login yet)'
					try {
						const dt = parseISO(value)
						value = `<span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true})}</span>`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'status':
					return UserStatus.match(value)
				case 'permissions':
					return UserLevel.match(value)
			}
			return value
		},
		sort(key) {

		},
		get,
	}
}
</script>

<style scoped lang="scss">
.list-users {
	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('border', 'light');
			display: grid;
			grid-template-columns: minmax(100px, 2fr) minmax(100px, 1fr) repeat(auto-fit, minmax(75px, 1fr));

			@include modules.media-query('phone') {
				grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);
			}
		}

		.list-users__app-list {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	.users-header-item {
		@include modules.fontSize(14px);
		align-items: center;
		display: flex;
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.users-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		overflow-wrap: break-word;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

		&--pending {
			&.users-item--status {
				color: modules.color_('alert', 'warn');
			}
		}

		&--removed {
			opacity: 0.7;

			&.users-item--status {
				color: modules.color_('alert', 'error')
			}
		}
	}
}
</style>
