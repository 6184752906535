<template>
	<form class="form form--user form-user" @submit.prevent="submitForm(fields)">
		<div class="form__loading" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" v-model="fields.firstName" :required="true" />
				<text-field class="form__field" label="Last Name" v-model="fields.lastName" :required="true" />
				<text-field class="form__field" label="Email Address" type="email" autocomplete="email" v-model="fields.email" :required="true" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="userStatus" placeholder="Select Status..." :required="true" />
				<text-field class="form__field" type="tel" label="Phone" v-model="fields.phone" :required="true" />
				<select-field class="form__field" label="Two-Factor Authentication" v-model="fields.otpMethod" :options="mfaList" :required="true" />
				<select-field class="form__field" label="Client" v-model="fields.clientId" :options="clientsList"  placeholder="Select Client..." :required="true" v-if="clientsList.length" />
				<select-field class="form__field" label="User Level" v-model="fields.permissions" :options="permissions" placeholder="Select User Level..." :required="true" v-if="permissions.length" />
				<checkbox class="form__field" label="Send Password Reset" v-model="fields.sendMail" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>
			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					{{ fields.uid ? 'Update' : 'Create' }} User
				</mdc-button>

				<mdc-button class="mdc-button--warn form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.uid && fields.status != 'removed'">
					Remove User
				</mdc-button>
			</div>
		</div>

	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import MdcButton from '@/components/MdcButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { UserStatus, UserLevel, OtpMethod, } from '@/lib/enums'

export default {
	name: 'FormUser',
	components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextField,
	},
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			status: UserStatus.Active,
		},
		fields: {
			permissions: {},
		},
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		me() {
			return this.$store.getters['user/me']
		},
		userStatus() {
			return UserStatus.toDropdown()
		},
		clientsList() {
			const clientsList = this.$store.getters['client/clients'].map(c => ({
				value: c.clientId,
				label: c.name,
			}))

			return clientsList
		},
		mfaList() {
			return [
				{
					label: 'SMS (Phone)',
					value: OtpMethod.hotp,
				},
				{
					label: 'Authenticator App',
					value: OtpMethod.totp,
				},
			]
		},
		permissions() {
			const { permissions } = this.me
			let options = [
				permissions == UserLevel.Admin ? {
					value: UserLevel.Admin,
					label: 'QP Admin',
				} : null,
				permissions == UserLevel.Admin || permissions == UserLevel.Internal ? {
					value: UserLevel.Internal,
					label: 'QP Employee',
				} : null,
				permissions == UserLevel.Admin || permissions == UserLevel.Internal ? {
					value: UserLevel.Client,
					label: 'Client',
				} : null,
				permissions == UserLevel.Admin || permissions == UserLevel.Internal || permissions == UserLevel.Client || permissions == UserLevel.Manager ? {
					value: UserLevel.Manager,
					label: 'Client Manager',
				} : null,
				permissions == UserLevel.Admin || permissions == UserLevel.Internal || permissions == UserLevel.Client || permissions == UserLevel.Manager ? {
					value: UserLevel.Employee,
					label: 'Client Employee',
				} : null,
			]

			return options.filter(n => n)
		},
	},
	mounted() {
		switch (this.me.permissions) {
			case UserLevel.Admin:
			case UserLevel.Internal:
				this.$store.dispatch('client/list')
				break
		}
	},
	methods: {
		async submitForm(fields) {
			console.log({ fields, })
			if (fields.uid) {
				try {
					const { uid } = fields
					await this.$store.dispatch('user/update', { uid, data: fields, } )
					this.$notice(`User updated`)
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
					console.error(error)
				}
			} else {

				try {
					await this.$store.dispatch('user/create', fields)
					this.$notice(`User created`)
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
			}

		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
